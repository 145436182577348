<template>
  <v-row>
    <v-col>
      <v-text-field
        v-model="min"
        hide-details
        clearable
        type="number"
        label="min"
        :rules="[rulesMin]"
        @input="onMinChange"
        @click:clear="onClear"
        v-on:keyup.enter="onEnter"
      ></v-text-field>
    </v-col>
    <v-col>
      <v-text-field
        v-model="max"
        hide-details
        clearable
        type="number"
        label="max"
        :rules="[rulesMax]"
        @input="onMaxChange"
        @click:clear="onClear"
        v-on:keyup.enter="onEnter"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    max: null,
    min: null,
  }),
  props: {
    filter_key: {
      type: String
    },
    label: {
      type: String,
    }
  },
  methods: {
    format() {
      if (this.min && this.max) {
        return {
          operator: 'BETWEEN',
          value: [this.min, this.max]
        };
      } else if (this.min) {
        return {
          operator: '>',
          value: this.min
        };
      } else if (this.max) {
        return {
          operator: '<',
          value: this.max
        };
      } else {
        return undefined;
      }
    },
    rulesMax(val) {
      if (!this.min || !val) return true;
      return this.min < parseInt(val);
    },
    rulesMin(val) {
      if (!this.max || !val) return true;
      return this.max > parseInt(val);
    },
    onMaxChange(val) {
      val = parseInt(val);

      if (!this.rulesMax(val)) {
        this.max = null;
      }

      this.max = val ? val : null;

      this.$emit("change", this.filter_key, this.format());
    },
    onMinChange(val) {
      val = parseInt(val);

      if (!this.rulesMin(val)) {
        this.min = null;
      }

      this.min = val ? val : null;

      this.$emit("change", this.filter_key, this.format());
    },
    onClear() {
      this.filter = this.format();
      this.$emit("onEnter", this.filter_key, this.format());
    }
  },
};
</script>
